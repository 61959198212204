import React from "react"
import get from 'lodash/get'
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HeaderSection from '../components/Contact/HeaderSection/HeaderSection'
import ContactContent from '../components/Contact/ContactContent/ContactContent'

const Contact = props => {
  const seoData = get(props, 'data.contactData.nodes[0].seo', [])
  const companyCredentiels = props.data.allCraftGlobalSetInterface.nodes[1]

  let data = {
    title: seoData.title,
    description: seoData.description,
    lang: 'nl',
    image: seoData.social.facebook.image && seoData.social.facebook.image.url,
    imageTwitter: seoData.social.twitter.image && seoData.social.twitter.image.url,
    robots: seoData.advanced.robots && seoData.advanced.robots
  }

  // slugs for translation
  const slugs = {
    slugNl: props.data.contactData.nodes[0].slug,
    slugEn: props.data.translatedUrl.nodes[0].slug,
  }

  return(
    <Layout form={false} data={props.data.site} location={props.location} slugs={slugs}>
      <SEO {...data}/>
      <HeaderSection/>
      <ContactContent data={data} companyData={companyCredentiels}/>
    </Layout>
  )
}

export default Contact

export const query = graphql`
{
  site{
    siteMetadata{
      languages {
        defaultLangKey
        langs
      }
    }
  }
  allCraftGlobalSetInterface{
    nodes{
      ... on craft_companySocials_GlobalSet {
        id
        name
        twitterUrl
        tiktokUrl
        linkedinUrl
        instagramUrl
      }
    }
  }
  translatedUrl:allCraftContactContactEntry(filter: {sectionHandle: {eq: "contact"}, siteId: {eq: 1}}) {
    nodes{
      slug
    }
  }
  contactData:allCraftContactContactEntry(filter: {sectionHandle: {eq: "contact"}, siteId: {eq: 2}}) {
    nodes {
      title
      seo {
        title
        description
        social {
          facebook {
            image {
              url
            }
          }
          twitter {
            image {
              url
            }
          }
        }
        advanced {
          robots
        }
      }
    }
  }
}
`